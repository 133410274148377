@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto:wght@400;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: #171717;
}

.bounce {
  animation: bounce 2s infinite;
}

#Hero {
  background-image: url('./assets/lewsr-bg.jpg');
  background-size: cover;
  background-position: center;
}

#About {
  background-color: #0A0A0A;
  background-image: url('./assets/Lewsr-bw.png');
  background-blend-mode: lighten;
  background-position: right;
  background-position-y: 20%;
  background-repeat: no-repeat;
}

#Podcast {
  background-image: url('./assets/lewsr-pod-bw.png');
  background-blend-mode: lighten;
  background-size: cover;
}

@media screen and (max-width: 480px) {
  #About {
    background-position: center;
    background-position-y: 10%;
  }
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}


.blink {
  animation: blink-animation 0.5s steps(5, start) infinite;
  -webkit-animation: blink-animation 0.5s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
